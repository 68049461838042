<template>
    <div>                     
        <div class="options" v-if="question.type === 'radio'">
            <button
                class="radio"
                :class="{ active: value === index, multiline: option.help, 'is-invalid': question.error }"
                v-for="(option, index) in question.options"
                :key="index"
                @click="select(index)"
            >
                {{ option.text }}

                <span v-if="option.help" v-text="option.help"></span>
            </button>
        </div>
    </div>
</template>

<script>

export default {
    props: ['question'],

    data() {
        return {
            value: this.question.value,
        }
    },

    methods: {
        select: function (value) {
            this.value = value.toString();
            var dependants = {};

            if (this.question.show) {
                dependants = this.question.show;
            }

            this.$emit('updated', {
                key: this.question.key,
                value: this.value,
                dependants
            });

            this.question.error = null;
        }
    }
}
</script>